import {MainPage} from "./modules/landing/ui/MainPage/MainPage";

function App() {
	return (
		<div>
			<MainPage />
		</div>
	);
}

export default App;
