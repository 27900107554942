import React, {ButtonHTMLAttributes} from 'react';
import styles from "./JoinButton.module.scss";
import cl from "classnames";

interface IJoinButton extends ButtonHTMLAttributes<HTMLButtonElement> {
    children: any;
    className?: string;
}

const JoinButton:React.FC<IJoinButton> = ({children, className, ...props}) => {

    return (
        <button className={cl(styles.btn, className)} {...props}>
            {children}
        </button>
    );
};

export default JoinButton;