import {useEffect, useRef, useState} from "react";
import styles from "./NftDrop.module.scss";
import player1 from "src/modules/landing/assets/img/png/playerHuge1.png";
import player2 from "src/modules/landing/assets/img/png/playerHuge2.png";
import player3 from "src/modules/landing/assets/img/png/playerHuge3.png";
import player4 from "src/modules/landing/assets/img/png/playerHuge4.png";
import sandClockSpritelist from "src/modules/landing/assets/img/png/sandClockSpritelist.png";
import {useWindowSize} from "@uidotdev/usehooks";
import {jSprite} from "src/modules/common/utils/JSprite.js"

export const NftDrop = () => {
	const containerScroll = useRef<HTMLDivElement>(null);
	const containerPlayer1 = useRef<HTMLImageElement>(null);
	const containerPlayer2 = useRef<HTMLImageElement>(null);
	const containerPlayer3 = useRef<HTMLImageElement>(null);
	const containerPlayer4 = useRef<HTMLImageElement>(null);
	const containerCircle = useRef<HTMLDivElement>(null);
	const windowSize = useWindowSize();

	const [scroll, setScroll] = useState<number>(0);

	const [isShowCircle, setIsShowCircle] = useState<boolean>(false);

	useEffect(() => {
		const handleScroll = () => {
			setScroll(window.scrollY);
		};

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	useEffect(() => {
		if(windowSize.width!<780)
		{
			if (scroll > 3200 && scroll < 3900 && containerScroll.current) {
				console.log("focus");

				containerScroll.current.focus();
				containerScroll.current.click();

				containerScroll.current.onclick = () => {
					console.log("click");
				};
		}
		}else{
			if (scroll > 2300 && scroll < 3100 && containerScroll.current) {
				console.log("focus");

				containerScroll.current.focus();
				containerScroll.current.click();

				containerScroll.current.onclick = () => {
					console.log("click");
				};
		}}
	}, [scroll]);

	useEffect(() => {
		let sizePlayerHeight4 = 1;
		let sizePlayerHeight3 = 1;
		let sizePlayerHeight2 = 1;
		let sizePlayerTop4 = 0;
		let sizePlayerTop3 = 0;
		let sizePlayerTop2 = 0;
		let sizePlayerTop1 = 0;
		let sizePlayerLeft4 = 0;
		let sizePlayerRight3 = 0;
		let sizePlayerRight2 = 0;
		let sizePlayerLeft1 = 0;
		let sizePlayerRotate4 = 1;
		let sizePlayerRotate3 = 1;
		let sizePlayerRotate2 = 1;
		let sizePlayerRotate1 = 1;
		let opacity = 0;
		if (
			containerPlayer1.current &&
			containerPlayer2.current &&
			containerPlayer3.current &&
			containerPlayer4.current &&
			containerScroll.current
		) {
			window.onscroll = (event) => {
				console.log("scroll", window.scrollY);
				let areaReak=2200
				if(windowSize.width!<780){
					areaReak=3000
				}

				if (window.scrollY > areaReak) {
					console.log("on scroll");
					if (sizePlayerHeight4 >= 0.7) {
						sizePlayerHeight4 -= 0.1;
					}
					if (sizePlayerHeight3 >= 0.9) {
						sizePlayerHeight3 -= 0.1;
					}
					if (sizePlayerHeight2 >= 0.6) {
						sizePlayerHeight2 -= 0.1;
					}
					if (sizePlayerTop4 > -20) {
						sizePlayerTop4 -= 10;
					}
					if (sizePlayerTop3 < 190) {
						sizePlayerTop3 += 10;
					}
					if (sizePlayerTop2 > -50) {
						sizePlayerTop2 -= 10;
					}
					if (sizePlayerTop1 < 90) {
						sizePlayerTop1 += 10;
					}
					if (sizePlayerLeft4 > -80) {
						sizePlayerLeft4 -= 10;
					}
					if (sizePlayerRight3 < 50) {
						sizePlayerRight3 += 10;
					}
					if (sizePlayerRight2 > -40) {
						sizePlayerRight2 -= 10;
					}
					if (sizePlayerLeft1 < 130) {
						sizePlayerLeft1 += 10;
					}
					if (sizePlayerRotate4 < 18.5) {
						sizePlayerRotate4 += 0.5;
					}
					if (sizePlayerRotate3 < 11.5) {
						sizePlayerRotate3 += 0.5;
					}
					if (sizePlayerRotate2 > -36) {
						sizePlayerRotate2 -= 3;
					}
					if (sizePlayerRotate1 > -13) {
						sizePlayerRotate1 -= 1;
					}
					opacity += 0.1;

					containerPlayer4.current!.style.height = `${
						396 * sizePlayerHeight4
					}px`;
					containerPlayer4.current!.style.position = `absolute`;
					containerPlayer4.current!.style.top = `${sizePlayerTop4}px`;
					containerPlayer4.current!.style.left = `${sizePlayerLeft4}px`;
					containerPlayer4.current!.style.rotate = `${sizePlayerRotate4}deg`;

					containerPlayer3.current!.style.height = `${
						396 * sizePlayerHeight3
					}px`;
					containerPlayer3.current!.style.position = `absolute`;
					containerPlayer3.current!.style.top = `${sizePlayerTop3}px`;
					containerPlayer3.current!.style.right = `${sizePlayerRight3}px`;
					containerPlayer3.current!.style.rotate = `${sizePlayerRotate3}deg`;

					containerPlayer2.current!.style.height = `${
						396 * sizePlayerHeight2
					}px`;
					containerPlayer2.current!.style.position = `absolute`;
					containerPlayer2.current!.style.top = `${sizePlayerTop2}px`;
					containerPlayer2.current!.style.right = `${sizePlayerRight2}px`;
					containerPlayer2.current!.style.rotate = `${sizePlayerRotate2}deg`;

					containerPlayer1.current!.style.height = `${
						396 * sizePlayerHeight3
					}px`;
					containerPlayer1.current!.style.position = `absolute`;
					containerPlayer1.current!.style.top = `${sizePlayerTop1}px`;
					containerPlayer1.current!.style.left = `${sizePlayerLeft1}px`;
					containerPlayer1.current!.style.rotate = `${sizePlayerRotate1}deg`;

					setIsShowCircle(true);

					if (containerCircle.current) {
						containerCircle.current.style.opacity = `${opacity}`;
					}
				}
			};
		}
	}, [
		containerPlayer1.current,
		containerPlayer2.current,
		containerPlayer3.current,
		containerPlayer4.current,
		containerScroll.current,
	]);

    const [dots, setDots] = useState('...');
    const [index, setIndex] = useState(3);

	useEffect(() => {
		const interval = setInterval(() => {
			setDots((prevDots) => {
				let newDots = prevDots.slice(0, index) + ' ' + prevDots.slice(index + 1);
				if (index === 0) {
					newDots = '...';
					setIndex(3);
				} else {
					setIndex((prevIndex) => prevIndex - 1);
				}
				return newDots;
			});
		}, 500);

		return () => clearInterval(interval);
	}, [index]);

	useEffect(()=>{
		new jSprite({
			spriteSheet: sandClockSpritelist,
			container: "sandClock",
			columns: 3,
			rows: 1,
			startFrame: 1,
			timing: 200,
			autoStart: true,
			repeat: true,
		});

	},[])

	return (
		<div className={styles.content} ref={containerScroll}>
			{/* <div className={styles.contentScroll}}> */}
			<div className={styles.title}>nft drop is coming</div>
			<div className={styles.players}>
				<img
					src={player4}
					alt=""
					className={styles.player4}
					id="player4"
					ref={containerPlayer4}
				/>
				<img
					src={player1}
					alt=""
					className={styles.player1}
					id="player1"
					ref={containerPlayer1}
				/>
				<img
					src={player3}
					alt=""
					className={styles.player3}
					id="player3"
					ref={containerPlayer3}
				/>
				<img
					src={player2}
					alt=""
					className={styles.player2}
					id="player2"
					ref={containerPlayer2}
				/>
			</div>
			<div className={styles.circleBlock}>
				<div className={styles.hourglass} id={"sandClock"}>

				</div>
				<div className={styles.subTitle}>
					loading{dots}
				</div>
				<div className={styles.desc}>
					Stay tuned, we will release details of the drop soon
				</div>
			</div>
			{/* <div className={styles.wrap} ref={containerWrap}></div> */}
			{/* </div> */}
		</div>
	);
};
