import React from 'react';
import styles from "./AddictiveGameMechanics.module.scss";
import path from "src/modules/landing/assets/img/svg/path.svg";
import tap from "src/modules/landing/assets/img/png/tap.png";
import grow from "src/modules/landing/assets/img/svg/grow.svg";
import world from "src/modules/landing/assets/img/png/world.png";
import rewards from "src/modules/landing/assets/img/png/rewards.png";
import pepePlus from "src/modules/landing/assets/img/png/pepePlus.png";
import JoinButton from "../JoinButton/JoinButton";
import arrow from "../../assets/img/svg/arrowDiagonal.svg";
import shadows from "src/modules/landing/assets/img/svg/shadows.svg";
import {useWindowSize} from "@uidotdev/usehooks";
import ScrollAnimation from "react-animate-on-scroll";

const AddictiveGameMechanics = () => {

    const windowSize = useWindowSize();

    return (
        <div className={styles.content}>
            <img src={shadows} alt={""} className={styles.shadows}/>
            <div className={styles.title}>addictive game mechanics</div>
            <div className={styles.wrap}>
                <div className={styles.areaForContent}
                     style={windowSize.width! < 1052 ? {scale: `${(windowSize.width!/1024)}`} : {scale: "1"}}>
                    <img src={path} alt={""} className={styles.path}/>
                    <div className={styles.blockTap}>
                        <img src={tap} alt={""}/>
                        <div className={styles.titleBlock}>Tap</div>
                        <div className={styles.descBlock}>
                            The faster you tap, the faster you grow.
                        </div>
                    </div>
                    <div className={styles.blockGrow}>
                        <img src={grow} alt={""}/>
                        <div className={styles.titleBlock}>GROW</div>
                        <div className={styles.descBlock}>
                            Walk your own journey from a depressed doomer to a Sigma male.
                        </div>
                    </div>
                    <div className={styles.blockWorld}>
                        <img src={world} alt={""}/>
                        <div className={styles.titleBlock}>OPEN WORLD</div>
                        <div className={styles.descBlock}>
                            Get into martial arts, start a company, conquer Solcity in your own way.
                        </div>
                    </div>
                    <div className={styles.blockRewards}>
                        <img src={rewards} alt={""}/>
                        <div className={styles.titleBlock}>REWARDS</div>
                        <div className={styles.descBlock}>
                            Collect in-game loot, compete and win real $ money prizes.
                        </div>
                    </div>
                    <div className={styles.blockFrieds}>
                        <img src={pepePlus} alt={""}/>
                        <div className={styles.titleBlock}>INVITE FRIENDS</div>
                        <div className={styles.descBlock}>
                            Do your pushups together.
                        </div>
                    </div>
                </div>
            </div>
            <ScrollAnimation animateIn={styles.animate}
                             initiallyVisible={true}>
            <JoinButton className={styles.btn} onClick={()=>{
                window.open("https://t.me/pepexversebot/play");
            }}>
                <div>join the app</div>
                <img src={arrow} alt="" />
            </JoinButton>
            </ScrollAnimation>
        </div>
    );
};

export default AddictiveGameMechanics;