import React from 'react';
import styles from "./HowToEarn.module.scss";
import battle from "src/modules/landing/assets/img/png/battle.png";
import giftWithRubin from "src/modules/landing/assets/img/png/giftWithRubin.png";
import star from "src/modules/landing/assets/img/png/star.png";
import shadowLeaderBoardBlock from "src/modules/landing/assets/img/svg/shadows/shadowLeaderBoardBlock.svg";
import shadowsCasinoBlocks from "src/modules/landing/assets/img/svg/shadows/shadowsCasinoBlocks.svg";
import shadowPvpBlock from "src/modules/landing/assets/img/svg/shadows/shadowPvpBlock.svg";
import JoinButton from "../JoinButton/JoinButton";
import arrow from "../../assets/img/svg/arrowDiagonal.svg";
import ScrollAnimation from "react-animate-on-scroll";

const HowToEarn = () => {


    return (
        <div className={styles.content}>
            <div className={styles.title}>how to earn</div>
            <div className={styles.areaBlocks}>
                <ScrollAnimation animateIn={styles.animate}
                                 initiallyVisible={true}>
                    <div className={styles.block1}>
                        <img src={star} alt={""} className={styles.star}/>
                        <img src={shadowLeaderBoardBlock} alt={""} className={styles.shadowLeaderBoardBlock}/>
                        <div className={styles.number}>01</div>
                        <div className={styles.titleBlock}>Weekly Leaderboard Contest</div>
                        <div className={styles.desc}>Dominate the leaderboard and earn $SOL weekly</div>
                    </div>
                </ScrollAnimation>
                <div className={styles.block2}>
                    <img src={giftWithRubin} alt={""} className={styles.giftWithRubin}/>
                    <img src={shadowsCasinoBlocks} alt={""} className={styles.shadowsCasinoBlocks}/>
                    <div className={styles.number}>02</div>
                    <div className={styles.titleBlock}>Casino</div>
                    <div className={styles.desc}>Coming soon</div>
                </div>
                <ScrollAnimation animateIn={styles.animate}
                                 initiallyVisible={true}>
                    <div className={styles.block3}>
                        <img src={battle} alt={""} className={styles.battle}/>
                        <img src={shadowPvpBlock} alt={""} className={styles.shadowPvpBlock}/>
                        <div className={styles.number}>03</div>
                        <div className={styles.titleBlock}>PVP mode</div>
                        <div className={styles.desc}>Coming soon</div>
                    </div>
                </ScrollAnimation>
            </div>

            <ScrollAnimation animateIn={styles.animateBtn}
                             initiallyVisible={true}>
            <JoinButton className={styles.btn} onClick={() => {
                window.open("https://t.me/pepexversebot/play")
            }}>
                <div>join the app</div>
                <img src={arrow} alt=""/>
            </JoinButton>
            </ScrollAnimation>
        </div>
    );
};

export default HowToEarn;