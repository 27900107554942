import React, {useEffect, useRef, useState} from 'react';
import styles from "./FrogArea.module.scss"
import frogMan from "src/modules/landing/assets/img/png/frogMan.png";
import tap from "src/modules/landing/assets/img/png/tap.png";
import skils from "src/modules/landing/assets/img/png/skils.png";
import {useWindowSize} from "@uidotdev/usehooks";

const FrogArea = () => {
    const containerFrog = useRef<HTMLDivElement>(null);
    const [clicks, setClicks]= useState<number>(0);
    const [level, setLevel]=useState<number>(0)
    const containerClick = useRef<HTMLImageElement>(null);
    const windowSize = useWindowSize();

    const amountLevelUp=1000;

    const handleClick = (ev) => {
        setClicks(clicks + 1);

        const containerRect = containerFrog.current!.getBoundingClientRect();
        const plusEl = document.createElement("div");
        plusEl.className = styles.plus;
       if(windowSize.width! < 780){
           plusEl.style.top = `${ev.clientY - containerRect.top + 100}px`;
           plusEl.style.left = `${ev.clientX - containerRect.left + 164}px`;
       }else{
           plusEl.style.top = `${ev.clientY - containerRect.top + 70}px`;
           plusEl.style.left = `${ev.clientX - containerRect.left + 320}px`;
       }
        plusEl.append(`+1`);

        requestAnimationFrame(() => containerFrog.current!.appendChild(plusEl));
    }

    useEffect(()=>{
        if(clicks>=amountLevelUp){
            setLevel(level+1);
            setClicks(0);
        }
        if(clicks===1){
            if(containerClick.current){
                console.log("hello")

                containerClick.current.style.opacity="0";
            }
        }
    },[clicks])

    return (
        <div className={styles.content}>
            <div className={styles.wrap}>

                <div className={styles.blocks}>
                    <div className={styles.leftBlock}>
                        <div className={styles.title}>
                            <div>gymbro</div>
                        </div>
                        <div>
                            <div className={styles.dialogWindow} style={level>0?{opacity:"1"}:{opacity:"0"}}>
                                <div>
                                    Stronger every day, inside and out.
                                </div>
                            </div>
                            <div ref={containerFrog}>
                                <img src={frogMan} alt={""} className={styles.frogMan} onClick={handleClick}/>
                            </div>
                            <img src={tap} alt={""} className={styles.tap} ref={containerClick}/>
                        </div>
                    </div>
                    <div className={styles.rightBlock}>
                        <div className={styles.title}>
                            <div>
                                skill progress
                            </div>
                        </div>
                        <div className={styles.wrapBlock}>
                            <div className={styles.iconStrange}>
                                <div>Lv. {level}</div>
                            </div>
                            <div className={styles.nameAchive}>
                                testosterone
                            </div>
                            <div className={styles.progressBar}>
                                <div className={styles.progress} style={{width:`${clicks/amountLevelUp*100}%`}}>

                                </div>
                                <div className={styles.text}>{clicks}/{amountLevelUp}</div>
                            </div>
                            <img src={skils} alt={""} className={styles.skils}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FrogArea;